import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { Header } from '@ecomm/header-redesigned'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import {
  ContentfulRichText,
  TrustpilotUKTopBanner
} from '@ecomm/shared-components'
import {
  FacebookLogo,
  LinkedInLogo,
  PinterestLogo,
  TwitterLogo,
  YoutubeLogo
} from '@ecomm/shared-icons'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import ImageLinkGrid from '../../components/common/ImageLinkGrid'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'
import { parsePressKitData } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly footerId: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function UnsubscribeEmailTemplate<
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>({ data, pageContext: { locale, seoDetails, footerId } }: Props<T>) {
  const { content } = parsePressKitData(data)

  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={'press_kit'}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        <div className="max-w-8xl prose-h2:text-2xl mx-auto mb-16 px-4 py-6 md:px-8 md:py-10 lg:py-12">
          <TrustpilotUKTopBanner className="pb-4 md:pb-6 lg:pb-8" />
          <div
            className="prose-h1:mb-4 md:prose-h1:mb-6 flex flex-col gap-4 md:gap-8 lg:gap-12"
            key="icon-container"
          >
            <div className="ml-auto flex items-center gap-4">
              <a
                aria-label="Twitter"
                className="h-7 w-10"
                href="https://x.com/simplisafe"
                key="twitter-icon"
              >
                <TwitterLogo className="text-neutral-medium-100 hover:text-complementary-blue-100 h-7 w-full" />
              </a>
              <a
                aria-label="Pinterest"
                className="h-12 w-10"
                href="https://www.pinterest.com/simplisafe/"
                key="pinterest-icon"
              >
                <PinterestLogo className="text-neutral-medium-100 hover:text-complementary-blue-100 h-12 w-full" />
              </a>
              <a
                aria-label="Facebook"
                className="h-7 w-10"
                href="https://www.facebook.com/SimpliSafe"
                key="facebook-icon"
              >
                <FacebookLogo className="text-neutral-medium-100 hover:text-complementary-blue-100 h-7 w-full" />
              </a>
              <a
                aria-label="Youtube"
                className="h-7 w-10"
                href="https://www.youtube.com/channel/UCwGgZULVml0IfSS26z1564g"
                key="youtube-icon"
              >
                <YoutubeLogo className="text-neutral-medium-100 hover:text-complementary-blue-100 h-7 w-full" />
              </a>
              <a
                aria-label="LinkedIn"
                className="h-12 w-10"
                href="https://www.linkedin.com/company/simplisafe"
                key="linkedin-icon"
              >
                <LinkedInLogo className="text-neutral-medium-100 hover:text-complementary-blue-100 h-12 w-full" />
              </a>
            </div>
            {content.content.map((component, i) =>
              match(component)
                .with({ __typename: 'ContentfulSmallText' }, data => (
                  <div>
                    <ContentfulRichText {...data.text} key={`rich-text-${i}`} />
                  </div>
                ))
                .with({ __typename: 'ContentfulGrid' }, data => (
                  <ImageLinkGrid
                    items={data.gridItems}
                    key={`link-grid-${i}`}
                  />
                ))
                .otherwise(() => null)
            )}
          </div>
        </div>
        <Footer footerId={footerId} />
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query PressKitPage {
    content: contentfulContentCollection(
      contentful_id: { eq: "4iq6F0zNWPt6rLo9ZC84Rl" }
    ) {
      contentful_id
      content {
        ... on ContentfulGrid {
          __typename
          contentful_id
          headline
          gridItems {
            ... on ContentfulImageLink {
              ...miscImageLinkFragment
            }
          }
        }
        ... on ContentfulSmallText {
          __typename
          contentful_id
          text {
            raw
          }
        }
      }
    }
  }
`
