import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { Header } from '@ecomm/header-redesigned'
import { TrustpilotUKTopBanner } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import type { PageProps } from 'gatsby'

import ReferAFriendPlaceholder from '../../components/ReferAFriendPlaceholder'
import { useHeaderRedesignQuery } from '../../hooks/useHeaderRedesignQuery'
import type { SeoNodeSchema } from '../../schemas/seo'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly placeholderType: string
  readonly footerId: string
}

type Props = Partial<PageProps> & {
  readonly pageContext: PageContext
}

export default function ReferAFriendTemplate({
  pageContext: { locale, seoDetails, placeholderType, footerId }
}: Props) {
  const headerData = useHeaderRedesignQuery()

  return (
    <TrackingProvider metaTitle={'refer-a-friend'}>
      <SEO
        canonicalLink={seoDetails.canonicalLink ?? ''}
        isNofollow={seoDetails.isNofollow}
        isNoindex={seoDetails.isNoindex}
        lang={locale}
        metaDescription={seoDetails.metaDescription?.metaDescription ?? ''}
        metaKeywords={seoDetails.metaKeywords ?? []}
        metaTitle={seoDetails.metaTitle}
      />
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <Header {...headerData} />
        <div className="max-w-8xl mx-auto flex flex-col gap-4 px-4 py-6 md:gap-8 md:px-8 md:py-10 lg:py-12">
          {locale === 'en-GB' ? <TrustpilotUKTopBanner /> : null}
          <ReferAFriendPlaceholder type={placeholderType} />
        </div>
        <Footer footerId={footerId} />
      </div>
    </TrackingProvider>
  )
}
